import * as THREE from 'three'
// import * as dat from 'lil-gui'
import { gsap } from 'gsap'



/**
 * Debug
 */


/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// texture loader 

const textureLoader = new THREE.TextureLoader()
const gradientTexture = textureLoader.load('/textures/gradients/3.jpg')
gradientTexture.magFilter = THREE.NearestFilter

// material 

const material = new THREE.MeshToonMaterial({
    color: '#548CA8',
    gradientMap: gradientTexture
})

/**
 *  Objects
 */

// meshes

const objectDistance = 4

const mesh1 = new THREE.Mesh(
    new THREE.SphereGeometry(1, 32, 32),
    material
)
material.wireframe = true;

mesh1.position.y = - objectDistance * 0
mesh1.position.x = .7

mesh1.scale.set(0.75, 0.75, 0.75)

const mesh2 = new THREE.Mesh(
    new THREE.TorusGeometry(.75, .4, 10, 60),
    material
)


mesh2.position.y = - objectDistance - 1
mesh2.position.x = -1
mesh2.scale.set(0.5, 0.5, 0.5)

const mesh3 = new THREE.Mesh(
    new THREE.TorusKnotGeometry(0.8, 0.35, 100, 16),
    material
)

mesh3.position.y = - objectDistance * 2
mesh3.position.x = 1
mesh3.scale.set(0.5, 0.5, 0.5)


const mesh4 = new THREE.Mesh(
    new THREE.SphereGeometry(.5, 32, 32),
    material
)

mesh4.position.y = -objectDistance * 3
mesh4.position.x = .5

scene.add(mesh1, mesh2, mesh3, mesh4)
const sectionMeshes = [mesh1, mesh2, mesh3, mesh4]

/**
 * particles
 */

const particlesCount = 350
const positions = new Float32Array(particlesCount * 3)

for (let i = 0; i < particlesCount; i++) {

    positions[i * 3] = (Math.random() - 0.5) * 10
    positions[i * 3 + 1] = objectDistance * 0.5 - Math.random() * objectDistance * sectionMeshes.length
    positions[i * 3 + 2] = (Math.random() - 0.5) * 10
}

const particlesGeometry = new THREE.BufferGeometry()
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

const particlesMaterial = new THREE.PointsMaterial(
    {
        color: '#548CA8',
        sizeAttenuation: true,
        size: 0.03
    }
)

const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)

// Light

const directionalLight = new THREE.DirectionalLight('#ffffff', 2)
directionalLight.position.set(1, 1, 0)
scene.add(directionalLight)



/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera

const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 6
cameraGroup.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * scroll
 */

let scrollY = window.scrollY
let currentSection = 0

window.addEventListener('scroll', () => {

    scrollY = window.scrollY
    const newSection = Math.round(scrollY / sizes.height)
    if (currentSection != newSection) {

        currentSection = newSection

        gsap.to(
            sectionMeshes[currentSection].rotation,
            {
                duration: 1.5,
                ease: 'power1.inOut',
                x: '+=6',
                y: '+=2',
                z: '+=1.5'
            }
        )

    }
})

/**
 * cursor
 */

const cursor = {}
cursor.y = 0;
cursor.x = 0;

window.addEventListener('mousemove', (event) => {

    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5

})

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0;

const tick = () => {
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime


    // animate camera 

    camera.position.y = -scrollY / sizes.height * 4

    const parallaxX = - cursor.x
    const parallaxY = cursor.y

    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 3 * deltaTime
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 3 * deltaTime

    // rotate objects

    for (const mesh of sectionMeshes) {

        mesh.rotation.x += deltaTime * 0.2
        mesh.rotation.y += deltaTime * 0.22

    }

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()